import { keyBy } from 'lodash';
import { QualificationConfig } from '@cambridgeassessment/checkpoint-dtos';

const qualifications: QualificationConfig[] = [
  {
    id: 3334,
    name: 'Cambridge Primary',
    media: 'DP964',
    ageRanges: {
      andUnder: 10,
      mainGroup: 11,
      andOver: 12,
    },
    color: '#0c6cb5',
    e2l: '0057',
    oldE2L: '0837',
  },
  {
    id: 299,
    name: 'Cambridge Lower Secondary',
    media: 'DP965',
    ageRanges: {
      andUnder: 13,
      mainGroup: 14,
      andOver: 15,
    },
    color: '#558417',
    e2l: '0876',
    oldE2L: '1110',
  },
];
const qualificationMap = keyBy<QualificationConfig>(qualifications, (x) => x.id);

export const getQualification = (qualificationId: number): QualificationConfig => {
  return qualificationMap[qualificationId];
};

export const getQualifications = (): QualificationConfig[] => qualifications;
